import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import { useAuth } from 'contexts/AuthContext'

import Logotype from 'components/Logotype/Logotype'
import SideMenuNav from 'components/SideMenuNav/SideMenuNav'

import styles from './SideMenu.module.css'

interface SideMenuProps {
  sideMenuOpen: boolean
  setSideMenuOpen: (a: boolean) => void
  button: React.ReactNode
}

const SideMenu: React.FC<SideMenuProps> = ({
  sideMenuOpen = false,
  setSideMenuOpen,
  button,
}) => {
  const ref = useRef(null)
  const { currentUser } = useAuth()

  useEffect(() => {
    const closeMenu = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setSideMenuOpen(false)
      }
    }

    document.addEventListener('mousedown', closeMenu)

    return () => {
      document.removeEventListener('mousedown', closeMenu)
    }
  }, [ref, setSideMenuOpen])

  return (
    <div
      className={cn(styles.sideMenu, {
        [styles.sideMenuOpen]: sideMenuOpen,
      })}
      ref={ref}
    >
      <div className={styles.sideMenuButton}>
        <Logotype size="sm" />
        {button}
      </div>
      <SideMenuNav auth={!!currentUser} setSideMenuOpen={setSideMenuOpen} />
    </div>
  )
}

export default SideMenu
