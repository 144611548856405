/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react'
import { Skeleton } from 'antd'
import type {
  GetSideMenuQuery,
  PublicCmsItemProfileCollectionTagList,
  PublicProfileCollection,
} from 'apollo-gql'
import { useMarketContext } from 'contexts/MarketContext'
import { useTranslation } from 'lib/i18n'

import Link from 'components/Link/Link'
import useLanguage from 'hooks/useLanguage'
import useSideMenu from 'hooks/useSideMenu'

import styles from './SideMenuNav.module.css'

type SideMenuNavProps = {
  auth?: boolean
  setSideMenuOpen: (state: boolean) => void
}

const findTags = (
  items: GetSideMenuQuery['market']['sideMenu']['cmsItems'],
) => {
  const result = (items?.find(
    (cmsItem) => cmsItem.type === 'ProfileCollectionTagList',
  ) || {}) as PublicCmsItemProfileCollectionTagList

  const tags =
    result.profileCollections?.filter(
      (pc: PublicProfileCollection) => pc.publicCmsItem !== null,
    ) ?? []

  return tags
}

const SideMenuNav = ({
  auth = false,
  setSideMenuOpen,
}: SideMenuNavProps): JSX.Element => {
  const { t } = useTranslation()
  const { market } = useMarketContext()
  const language = useLanguage()
  const { loading, sideMenu } = useSideMenu()

  const closeSideMenu = useCallback(() => {
    setSideMenuOpen(false)
  }, [setSideMenuOpen])

  const { cmsItems } = sideMenu || {}
  const tags = findTags(cmsItems)

  const { businessPage, blogPage } = market.selectedMarket?.config || {}
  const businessPageLink =
    businessPage && (businessPage as string)[language.code]
  const blogPageLink = blogPage && (blogPage as string)[language.code]

  return (
    <nav className={styles.sideMenuNav}>
      {auth ? (
        <Link href="/account/orders" prefetch={false}>
          <a onClick={closeSideMenu}>{t('sideMenu.account', 'Account')}</a>
        </Link>
      ) : (
        <Link href="/auth/login" prefetch={false}>
          <a onClick={closeSideMenu}>{t('sideMenu.login', 'Log in')}</a>
        </Link>
      )}
      {businessPageLink && (
        <Link href={businessPageLink} prefetch={false}>
          <a onClick={closeSideMenu}>
            {t('sideMenu.businessPage', 'For business')}
          </a>
        </Link>
      )}
      <Link href="/enroll" prefetch={false}>
        <a onClick={closeSideMenu}>
          {t('sideMenu.enroll', 'Enroll as Profile')}
        </a>
      </Link>
      {blogPageLink && (
        <Link href={blogPageLink} prefetch={false}>
          <a onClick={closeSideMenu}>{t('sideMenu.blog', 'Blogg')}</a>
        </Link>
      )}
      <Link href="/faq" prefetch={false}>
        <a onClick={closeSideMenu}>
          {t('sideMenu.helpAndContact', 'Help & Contact')}
        </a>
      </Link>

      <div className={styles.sideMenuNavCategories}>
        <span className={styles.sideMenuNavRow}>
          {t('sideMenu.categories', 'Find talents')}
        </span>

        {loading && (
          <div className={styles.sideMenuNavSkeleton}>
            <Skeleton
              active
              title={false}
              paragraph={{
                rows: 3,
                width: ['60%', '70%', '65%'],
              }}
            />
          </div>
        )}

        {!loading &&
          tags.map((tag) => {
            const { publicCmsItem, slug } = tag
            const MEMMO_RED = '#f8284e'
            const backgroundColor = publicCmsItem?.color ?? MEMMO_RED

            return (
              <Link
                key={`nav-category-${slug}`}
                href={publicCmsItem?.linkPath || `/profiles/${slug}`}
                prefetch={false}
              >
                <a
                  className={styles.sideMenuNavCategory}
                  onClick={closeSideMenu}
                >
                  <span
                    className={styles.sideMenuNavCategoryDot}
                    style={{
                      backgroundColor,
                    }}
                  />
                  {publicCmsItem?.title}
                </a>
              </Link>
            )
          })}
      </div>
    </nav>
  )
}

export default SideMenuNav
